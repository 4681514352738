import React from 'react';
import Checkbox from '../Checkbox/Checkbox';

const Fieldset = (props) => {
	function checkboxChange(evt) {
		const newChecked = props.data;
		// Loop through the array of objects to find status of the Checkbox components
		newChecked.forEach(function(x) {
			if (x.key === evt.target.id) {
				x.value = evt.target.checked;
			}
		});
		props.eventHandler(newChecked);
	}

	return (
		<fieldset id={props.id}>
			<legend className='text-sm font-medium text-gray-700'>
				{props.title}
			</legend>
			<label
				htmlFor={props.id}
				className='block text-sm font-medium leading-5 text-gray-700'>
				{props.label}
			</label>
			<div className={props.classes}>
				<>
					{props.data.map((value) => {
						return (
							<Checkbox
								fieldset={props.id}
								key={value.key}
								data={value.key}
								checked={value.value}
								eventHandler={checkboxChange}
							/>
						);
					})}
				</>
			</div>
		</fieldset>
	);
};

export default Fieldset;
