import React from 'react';

const OptionGroup = (props) => {
	return (
		<div>
			<label
				htmlFor={props.id}
				className='block text-sm leading-5 font-medium text-gray-700'>
				{props.label}
			</label>
			<div className='mt-1 sm:mt-0 sm:col-span-2'>
				<div className='max-w-xs rounded-md shadow-sm'>
					<select
						id={props.id}
						className='mt-1 form-select rounded-sm block w-full pl-3 pr-10 py-2 text-base leading-6 sm:text-sm sm:leading-5'
						onChange={props.eventHandler}>
						<option>- Select -</option>
						{props.data.map((item, index) => {
							return (
								<optgroup
									key={index}
									id={item.key}
									label={item.key}
									disabled={false}>
									{item.value.map((value2, index2) => {
										return <option key={index2}>{value2['name']}</option>;
									})}
								</optgroup>
							);
						})}
					</select>
				</div>
			</div>
		</div>
	);
};

export default OptionGroup;
