import React from 'react';

const Option = (props) => {
	return (
		<div>
			<label
				htmlFor={props.id}
				className='block text-sm font-medium leading-5 text-gray-700'>
				{props.label}
			</label>

			<div className='mt-1 sm:col-span-2'>
				<div className='max-w-xs rounded-md shadow-sm'>
					<select
						id='service_year'
						className='block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
						onChange={props.eventHandler}>
						<option>- Select -</option>
						{props.list.map((value) => {
							return <option key={value['value']}>{value['value']}</option>;
						})}
					</select>
				</div>
			</div>
		</div>
	);
};

export default Option;
