import React from 'react';
import { Link } from 'gatsby';
import Image from '../../fields/Image';
import Placeholder from '../../../images/picture_needed.png';
import Parser from 'html-react-parser';

export function Officer(props) {
	const data = props.data.node;
	let position = data.r.entity_id.r.field_position;
	const photo = data.r.entity_id.r.media;
	const body = data.r.entity_id.body;

	if (props.format === 'short' && position.length > 1) {
		// Using slice to copy the array because it is frozen on the list page.
		// https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
		let sorted = position
			.slice()
			.sort(
				(a, b) =>
					b.r.field_service_period[0].sortKey -
					a.r.field_service_period[0].sortKey
			);
		position = sorted;
	} else if (props.format === 'long' && position.length > 1) {
		let sorted = position.sort(
			(a, b) =>
				b.r.field_service_period[0].sortKey -
				a.r.field_service_period[0].sortKey
		);
	}
	if (props.format === 'short') {
		return (
			<div className='flex rounded-lg shadow-lg'>
				<Link to={data.r.entity_id.path.alias}>
					<div>
						<div className='w-full '>
							{photo !== null && (
								<Image
									image={photo}
									alt={data.label}
									className='mx-auto rounded-t-lg'
									sizes='300w'
									force='sm'
								/>
							)}
							{photo === null && (
								<img
									src={Placeholder}
									alt='Placeholder'
									className='mx-auto rounded-t-lg'
									force='sm'
								/>
							)}
						</div>
						<div className='text-left p-6'>
							<div className='font-title text-sm leading-tight md:text-lg md:leading-6 font-medium'>
								<h4>{data.label}</h4>
								{/* {position &&
										position.map((spot) => (
											<div
												className='text-indigo-600'
												key={spot.r.field_position.drupal_internal__tid}>
												{spot.r.field_position.name}
											</div>
										))} */}
							</div>
						</div>
					</div>
				</Link>
			</div>
		);
	} else if (props.format === 'long' && photo) {
		return (
			<div className='bg-white overflow-hidden'>
				<div className='relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
					<div className='mx-auto text-base max-w-prose lg:max-w-none'>
						<h1 className='font-title mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
							Meet {data.label}
						</h1>
					</div>
					<div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-8'>
						<div className='relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2'>
							<div className='relative text-base mx-auto max-w-prose lg:max-w-none'>
								<figure>
									<div className='relative pb-9/12 lg:pb-0'>
										<Image
											image={photo}
											alt={data.label}
											className='rounded-lg shadow-lg object-cover object-center inset-0 w-full h-full lg:static lg:h-auto'
											sizes='600w'
										/>
									</div>
								</figure>
							</div>
						</div>
						<div>
							<div className='text-base max-w-prose mx-auto lg:max-w-none'>
								{body && (
									<div className='font-body text-sm md:text-lg md:leading-7 text-gray-500 mb-5'>
										{Parser(body.processed)}
									</div>
								)}
								{!body && (
									<div className='text-lg leading-7 text-gray-500 mb-5'>
										Unfortunately, we do not have a biography for {data.label}.
										If you have information to share, please contact the Lodge
										Office. Thank you!
									</div>
								)}
								<div className='mt-20 p-5 bg-ap-light-gray'>
									{position.map((spot) => (
										<div
											className=''
											key={spot.r.field_position.drupal_internal__tid}>
											<div className='pt-3 text-base leading-6 text-ap-purple font-semibold  uppercase'>
												{spot.r.field_position.name}
											</div>
											<div className='flex flex-row'>
												{spot.r.field_service_period.map((term) => (
													<div
														className='text-xs pr-4 leading-2 text-gray-500  uppercase'
														key={term.drupal_internal__tid}>
														{term.name}
													</div>
												))}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (props.format === 'long') {
		return (
			<div className='relative py-16 bg-white overflow-hidden'>
				<div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
					<div className='relative h-full text-lg max-w-prose mx-auto'>
						<svg
							className='absolute top-12 left-full transform translate-x-32'
							width='404'
							height='384'
							fill='none'
							viewBox='0 0 404 384'>
							<defs>
								<pattern
									id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
									x='0'
									y='0'
									width='20'
									height='20'
									patternUnits='userSpaceOnUse'>
									<rect
										x='0'
										y='0'
										width='4'
										height='4'
										className='text-gray-200'
										fill='currentColor'
									/>
								</pattern>
							</defs>
							<rect
								width='404'
								height='384'
								fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
							/>
						</svg>
						<svg
							className='absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
							width='404'
							height='384'
							fill='none'
							viewBox='0 0 404 384'>
							<defs>
								<pattern
									id='f210dbf6-a58d-4871-961e-36d5016a0f49'
									x='0'
									y='0'
									width='20'
									height='20'
									patternUnits='userSpaceOnUse'>
									<rect
										x='0'
										y='0'
										width='4'
										height='4'
										className='text-gray-200'
										fill='currentColor'
									/>
								</pattern>
							</defs>
							<rect
								width='404'
								height='384'
								fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
							/>
						</svg>
						<svg
							className='absolute bottom-12 left-full transform translate-x-32'
							width='404'
							height='384'
							fill='none'
							viewBox='0 0 404 384'>
							<defs>
								<pattern
									id='d3eb07ae-5182-43e6-857d-35c643af9034'
									x='0'
									y='0'
									width='20'
									height='20'
									patternUnits='userSpaceOnUse'>
									<rect
										x='0'
										y='0'
										width='4'
										height='4'
										className='text-gray-200'
										fill='currentColor'
									/>
								</pattern>
							</defs>
							<rect
								width='404'
								height='384'
								fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
							/>
						</svg>
					</div>
				</div>
				<div className='relative px-4 sm:px-6 lg:px-8'>
					<div className='text-lg max-w-prose mx-auto mb-6 text-center'>
						<h1 className='mt-8 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
							Meet {data.label}
						</h1>
						{position.map((spot, index) => (
							<div>
								<div className='text-base leading-6 text-indigo-600 font-semibold  uppercase'>
									{spot.r.field_position.name}
								</div>
								<div className='flex flex-col'>
									{spot.r.field_service_period.map((term) => (
										<div className='text-xs pr-4 leading-2 text-gray-400  uppercase'>
											{term.name}
										</div>
									))}
								</div>
							</div>
						))}

						<p className='text-xl text-gray-500 leading-8'>
							{body && <div>{Parser(body.processed)}</div>}
							{!body && (
								<div className='text-center mt-12 md:mt-24'>
									Unfortunately, we do not have a biography or photo for{' '}
									{data.label}. <br />
									If you have information to share, please contact the Lodge
									Office. Thank you!
								</div>
							)}
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Officer;
