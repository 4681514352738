import React from 'react';
import Officer from './Officer';

const OfficerManager = (props) => {
	return (
		<div className='bg-white max-w-7xl text-left mx-6 lg:mx-0'>
			<div className='py-12 lg:py-24'>
				<div className=''>
					<div className='grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 xl:grid-cols-5'>
						{props.people.map((person) => (
							<Officer key={person.node.nid} data={person} format={'short'} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OfficerManager;
