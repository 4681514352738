import React from 'react';

const Checkbox = (props) => {
	return (
		<div key={props.data} className='flex items-center pt-2'>
			<input
				id={props.data}
				name='form-input branch'
				type='checkbox'
				className='form-checkbox h-4 w-4 text-ap-purple transition duration-150 ease-in-out'
				defaultChecked={props.checked}
				onClick={props.eventHandler}
			/>
			<label htmlFor={props.data} className='ml-2'>
				<span className='block text-xs leading-5 font-medium text-gray-700'>
					{props.data}
				</span>
			</label>
		</div>
	);
};

export default Checkbox;
