import React from 'react';
import { useState, useEffect } from 'react';
import OptionGroup from '../../fields/OptionGroup/OptionGroup';
import Option from '../../fields/Option/Option';
import Fieldset from '../../fields/Fieldset/Fieldset';
// import Transition from '../../helpers/Transition';

const OfficerFilter = (props) => {
	// Start: Define variables
	const titleList = props.positions.map((item) => {
		return { key: item['node']['name'], value: item['node']['r']['title'] };
	});

	const yearList = props.years.map((item) => {
		return { key: item['node']['name'], value: item['node']['name'] };
	});

	const defaultBranches = titleList.reduce((acc, item) => {
		acc.push({ key: item['key'], value: false });
		return acc;
	}, []);

	const initialOfficers = props.defaultItems;

	// End: Define variables

	// Start: Define state

	const [branchList, updateBranchList] = useState(defaultBranches);
	const [selectedName, updateSelectedName] = useState('');
	const [selectedPosition, updateSelectedPosition] = useState('- Select -');
	const [selectedYear, updateSelectedYear] = useState('- Select -');
	const [selectedBranch, updateSelectedBranch] = useState([]);
	// const [showFilter, updateShowFilter] = useState(true);

	// End: Define state

	useEffect(() => {
		const filteredList = initialOfficers.filter((person) => {
			return (
				(selectedName === '' ||
					person.node.label
						.toLowerCase()
						.includes(selectedName.toLowerCase())) &&
				(selectedPosition === '- Select -' ||
					checkPositionItem(person.node.r.entity_id.r.field_position)) &&
				(selectedYear === '- Select -' ||
					checkYearItem(person.node.r.entity_id.r.field_position)) &&
				(selectedBranch.length === 0 ||
					checkBranchItem(person.node.r.entity_id.r.field_position))
			);
		});
		props.setPeople(filteredList);
	}, [selectedName, selectedPosition, selectedYear, selectedBranch]);

	function checkPositionItem(item) {
		return item.some(
			(testVal) => testVal.r.field_position.name === selectedPosition
		);
	}

	function checkYearItem(item) {
		const years = [];

		item.map((subitem) => {
			subitem.r.field_service_period.map((year) => {
				years.push(year.name);
			});
		});

		return years.some((testVal) => testVal === selectedYear);
	}

	function checkBranchItem(item) {
		return item.some(
			(testVal) =>
				selectedBranch.indexOf(
					testVal.r.field_position.r.parent['0'].branch
				) !== -1
		);
	}

	function processName(evt) {
		props.updateName(evt.target.value);
		updateSelectedName(evt.target.value);
	}

	function processPosition(evt) {
		updateSelectedPosition(evt.target.value);
	}

	function processYear(evt) {
		updateSelectedYear(evt.target.value);
	}

	function processBranch(evt) {
		const activeBranches = [];
		evt.forEach((x) => {
			if (x.value) {
				activeBranches.push(x.key);
			}
		});
		updateSelectedBranch(activeBranches);
	}

	function resetPage(evt) {
		updateBranchList(defaultBranches);
		updateSelectedPosition('- Select -');
		updateSelectedYear('- Select -');
		updateSelectedName('');
		updateSelectedBranch([]);
		props.updateName('');
	}

	return (
		<div className='bg-gray-100'>
			{/* <div className='bg-indigo-50 w-full px-4 py-2 mt-6 mb-0 text-gray-700'>
				<button
					type='button'
					onClick={() => updateShowFilter(!showFilter)}
					className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'>
					<svg
						className='h-6 w-6 mr-2'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z'
						/>
					</svg>
					{showFilter ? 'Hide Search Filters' : 'Show Search Filters'}
				</button>
			</div> */}
			{/* <Transition
				show={showFilter}
				enter='duration-200 ease-out'
				enterFrom='opacity-0 scale-95'
				enterTo='opacity-100 scale-100'
				leaving='duration-100 ease-in'
				leaveFrom='opacity-100 scale-100'
				leaveTo='opacity-0 scale-95'> */}
			{/* <form
					id='officer_filters'
					className={`bg-indigo-50 ${showFilter ? '' : 'hidden'}`}> */}
			<form id='officer_filters'>
				<div className='w-full flex flex-col items-end justify-between p-6 space-y-4 lg:space-y-0 md:flex-row flex-wrap'>
					<div className='w-1/4 min-w-full md:min-w-0'>
						<label
							htmlFor='name'
							className='flex text-sm leading-5 font-medium text-gray-700'>
							Name
						</label>
						<div className='mt-1'>
							<input
								id='name'
								className='p-2 form-input rounded-sm shadow-sm border border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 min-w-full'
								value={props.currentName}
								onChange={processName}
								placeholder='Search by name...'
							/>
						</div>
					</div>
					<div className='min-w-full md:min-w-0'>
						<OptionGroup
							id={'position_title'}
							label={'Position'}
							data={titleList}
							eventHandler={processPosition}
						/>
					</div>
					<div className='min-w-full md:min-w-0'>
						<Option
							id={'service_year'}
							label={'Service Year'}
							list={yearList}
							eventHandler={processYear}
						/>
					</div>
					<div className='min-w-full md:min-w-0'>
						<Fieldset
							id={'branches'}
							label='Branches'
							data={branchList}
							eventHandler={processBranch}
							classes='lg:h-10 grid sm:grid-cols-3 md:grid-rows-1 md:gap-x-6'
						/>
					</div>
					<div className='pt-3 sm:col-span-2 lg:min-w-0'>
						<div className='max-w-xs rounded-md shadow-sm'>
							<input
								type='reset'
								className='inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ap-purple hover:bg-ap-purple-hover focus:outline-none  focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
								value='Reset Search Filters'
								onClick={resetPage}
							/>
						</div>
					</div>
				</div>
			</form>
			{/* </Transition> */}
		</div>
	);
};

export default OfficerFilter;
