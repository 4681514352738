// @TODO: Set the page title dynamically

import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import { graphql } from 'gatsby';
import Parser from 'html-react-parser';

import OfficerManager from '../../components/content_type/Officers/OfficerManager';
import OfficerFilter from '../../components/content_type/Officers/OfficerFilter';
import deepFreeze from 'deep-freeze';
import Content from '../../components/fields/Content';

const OfficerListPage = ({ data }) => {
	// Variables
	const defaultPeople = data.officerList.edges;
	const defaultBranches = data.positions.edges;
	const node = data.nodeListings;
	const { content } = data.nodeListings.r;
	deepFreeze(defaultPeople);
	deepFreeze(defaultBranches);

	// State
	const [currentName, updateName] = useState('');
	const [people, setPeople] = useState(defaultPeople);

	return (
		<Layout>
			<Seo title='Officers' keywords={[`gatsby`, `application`, `react`]} />
			<div className='bg-white max-w-7xl text-left mx-6 lg:mx-0'>
				<div className='py-12 lg:py-24'>
					<div className=''>
						<div className=''>
							<h2 className='font-title text-4xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
								{node.title}
							</h2>
							{node.subtitle && (
								<p className='text-xl leading-7 text-gray-500'>
									{node.subtitle}
								</p>
							)}
						</div>
						<div className='font-body font-semibold py-6 w-2/3 text-gray-900'>
							{node.summary && <div>{Parser(node.summary)}</div>}
						</div>
					</div>
				</div>
				<Content content={content} />
				<div className=''>
					<OfficerFilter
						currentName={currentName}
						years={data.service_years.edges}
						positions={data.positions.edges}
						officers={people}
						defaultItems={defaultPeople}
						defaultBranches={defaultBranches}
						updateName={updateName}
						setPeople={setPeople}
					/>
				</div>
				<OfficerManager people={people} node={data.nodeListings} />
			</div>
		</Layout>
	);
};

export default OfficerListPage;

export const query = graphql`
	query officers($gid: Int, $tid: Int, $slug: Int) {
		nodeListings(drupal_internal__nid: { eq: $slug }) {
			...listingPageFragment
		}
		officerList: allGroupContentLodgeGroupNodeOfficer(
			filter: {
				relationships: {
					gid: { drupal_internal__id: { eq: $gid } }
					entity_id: {
						relationships: {
							field_position: {
								elemMatch: {
									relationships: {
										field_service_period: {
											elemMatch: { drupal_internal__tid: { eq: $tid } }
										}
									}
								}
							}
						}
						status: { eq: true }
					}
				}
			}
			sort: { order: ASC, fields: label }
		) {
			edges {
				node {
					...officerFragment
				}
			}
		}
		lodge: groupLodge(drupal_internal__id: { eq: $gid }) {
			...lodgeFragment
		}
		positions: allTaxonomyTermOfficerPosition(
			filter: {
				relationships: {
					taxonomy_term__officer_position: {
						elemMatch: {
							id: { ne: null }
							relationships: {
								taxonomy_term__officer_position: {
									elemMatch: { name: { ne: null } }
								}
							}
						}
					}
				}
			}
		) {
			edges {
				node {
					name
					r: relationships {
						title: taxonomy_term__officer_position {
							name
						}
					}
				}
			}
		}
		service_years: allTaxonomyTermServiceYear(
			sort: { order: DESC, fields: name }
		) {
			edges {
				node {
					name
					sortKey: field_starting_year
				}
			}
		}
	}
`;
